<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <logo class="d-none d-lg-block" />
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Forgot password V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Forgot password-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <Locale
          class="mt-1"
          style="position:absolute; top:0"
        />
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto mt-5"
        >
          <b-card-title
            class="mb-1 text-center"
            title-tag="h2"
          >
            {{ $t('forget_password') }}
          </b-card-title>
          <b-card-text class="mb-2">
            {{ $t('forget_password_description') }}
          </b-card-text>

          <!-- form -->
          <validation-observer ref="simpleRules">
            <b-form
              class="auth-forgot-password-form mt-2"
              @submit.prevent="submit"
            >
              <b-form-group
                :label="$t('email')"
                label-for="forgot-password-email"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('email')"
                  rules="required|email"
                >
                  <b-form-input
                    id="forgot-password-email"
                    v-model="form.email"
                    :state="errors.length > 0 ? false:null"
                    name="email"
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group>
                <vue-recaptcha
                  ref="recaptcha"
                  class="mt-1"
                  :sitekey="sitekey"
                  :load-recaptcha-script="true"
                  @expired="onCaptchaExpired"
                  @verify="onVerify"
                />
              </b-form-group>
              <b-button
                type="submit"
                variant="primary"
                block
                class="mt-2"
                :disabled="!form.recaptcha"
              >
                <span v-if="!pending">{{ $t('send_reset_link') }}</span>
                <b-spinner
                  v-else
                  small
                  type="grow"
                />
              </b-button>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <b-button
              type="submit"
              variant="secondary"
              block
              :to="{name:'login'}"
            >
              {{ $t('back_to_login') }}
            </b-button>
          </p>
        </b-col>
      </b-col>
      <!-- /Forgot password-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import {
  BRow, BCol, BLink, BCardTitle, BCardText, BImg, BForm, BFormGroup, BFormInput, BButton, BSpinner,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import VueRecaptcha from 'vue-recaptcha'
import logo from '@/views/components/logo.vue'
import store from '@/store'
import AuthApis from '@/services/apis/auth'
import Locale from '@/layouts/components/Locale.vue'

const auth = new AuthApis()
export default {
  components: {
    logo,
    BRow,
    BCol,
    BLink,
    BImg,
    BForm,
    BButton,
    BFormGroup,
    BFormInput,
    BCardTitle,
    BCardText,
    BSpinner,
    VueRecaptcha,
    Locale,
  },
  data() {
    return {
      sideImg: require('@/assets/images/pages/forgot-password-v2.svg'),
      required,
      email,
      pending: false,
      form: {
        email: '',
        recaptcha: null,
      },
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/forgot-password-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
    sitekey() {
      return process.env.VUE_APP_RECAPTCHA_SITE_KEY
    },
  },
  methods: {
    async submit() {
      const success = await this.$refs.simpleRules.validate()
      if (success) {
        this.pending = true
        auth.forgotPassword(this.form).then(res => {
          this.$swal({
            title: res.data.message,
            icon: 'success',
            timer: 3000,
            showConfirmButton: false,
          })
          this.$router.push({ name: 'login' })
        }).finally(() => {
          this.onCaptchaExpired()
          this.pending = false
        })
      }
    },
    onVerify(response) {
      this.form.recaptcha = response
    },
    async onCaptchaExpired() {
      this.$refs.recaptcha.reset()
      this.form.recaptcha = null
    },
  },
}
</script>

<style lang="scss">
    @import '@core/scss/vue/pages/page-auth.scss';
    .auth-wrapper #dropdown-grouped::marker {
      content: '';
    }
</style>
